import React from 'react';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { Subscribe } from 'unstated';
import FormState from '../../state/uFormState';

export default function FormMultiLine(
  props: { name: string; label: string | undefined, readOnly: boolean }
) {
  const useLabel = props.label || _.startCase(props.name);
  return (
    <Subscribe to={[FormState]}>
    {(formState: FormState) => (
    <TextField
      name={props.name}
      label={useLabel}
      margin="normal"
      fullWidth
      onChange={formState.handleChange}
      multiline
      value={formState.state[props.name] || ''}
      style={{ marginLeft: '12px', marginRight: '12px', overflowX: 'hidden' }}
      disabled={props.readOnly}
    />
    )}
    </Subscribe>
  );
}
