import React from 'react';
import { Container } from 'unstated';
import DataStore from './uDataStore';
import { dataStore } from '../components/InputView';

interface Istate {
  [propName: string]: any;
}

export default class FormState extends Container<Istate> {
  public state: Istate = {};
  public hotData: Istate = {};
  public stateKey: string = '';
  public setField = (key: string, value: any) => {
    return this.setState({ [key]: value });
  }
  public clearForm = () => {
    return this.setState({});
  }
  public loadFromDataStore = (key: string) => {
    this.stateKey = key;
    this.setState(dataStore.state[key]);
    // dataStore.unsubscribe
    dataStore.subscribe(() => {
      this.setState(dataStore.state[key]);
    });
  }
  public getFormDataFromStore = () => {
    if (!this.stateKey.length || !dataStore.state[this.stateKey]) return {};
    return dataStore.state[this.stateKey];
  }
  public handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    return this.setState({ [name]: value });
  }
}
