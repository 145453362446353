import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { model, IdataField, Icategory } from '../types/model';
import FormTextElement from './formTypes/FormTextElement';
import { FieldChooser } from './FieldChooser';
import { Subscribe, Provider } from 'unstated';
import DataStore from '../state/uDataStore';
import FormState from '../state/uFormState';
import { dataStore } from './InputView';

interface Iprops {
  modelKey: string;
  readOnly: boolean;
}

interface Istate {
  isSubmitting: boolean;
}

export let formState = new FormState();

class FormView extends React.Component<Iprops, Istate> {
  constructor(props: Iprops) {
    super(props);
    formState = new FormState();
    this.state = {
      isSubmitting: false
    };
  }

  public render() {
    const modelIndex = _.findIndex(model, { key: this.props.modelKey });
    const set = model[modelIndex];
    return (
      <Provider inject={[formState]}>
        <Subscribe to={[DataStore]}>
          {(store: DataStore) => {
            formState.loadFromDataStore(this.props.modelKey);
            // formState.setState(store.state[model[this.props.modelIndex].key]);
            return (
              <form>
                <div style={{ flexGrow: 1, margin: '12px' }}>
                  <Paper style={{ paddingRight: '24px' }}>
                    <Grid container spacing={16}>
                      {set.inputGroups.map((g, index) => (
                        <FieldChooser readOnly={this.props.readOnly} key={index} inputGroup={g} />
                      ))}
                      {!this.props.readOnly ?
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.state.isSubmitting}
                          onClick={this.submit.bind(this, store)}
                        >
                          Save
                        </Button>
                      </Grid>
                      : null}
                    </Grid>
                  </Paper>
                </div>
              </form>
            );
          }}
        </Subscribe>
      </Provider>
    );
  }
  // public componentWillMount() {
  //   const set = model[this.props.modelIndex];
  //   // formState.loadFromDataStore(set.key);
  // }

  private submit = (store: DataStore) => {
    // const set = model[this.props.modelIndex];
    store
      .setCategory(this.props.modelKey, _.cloneDeep(formState.state))
      .then(() => {
        console.log(JSON.stringify(store.state));
      });
  }
}

export default FormView;
