import React from 'react';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import FormState from '../../state/uFormState';
import { Subscribe } from 'unstated';

export default function FormTextElement(
  props: {
    name: string;
    label: string | undefined;
    rows?: number;
    options?: string[];
    readOnly: boolean;
  }
) {
  const { name, label, rows, options } = props;
  const useLabel = label || _.startCase(name);
  const isSelect = options !== undefined && options.length > 0;
  return (
    <Subscribe to={[FormState]}>
    {(formState: FormState) => (
    <div>
      <TextField
        name={name}
        label={useLabel}
        margin="normal"
        fullWidth
        onChange={formState.handleChange}
        multiline={rows !== undefined && rows > 0}
        select={isSelect}
        value={formState.state[name] || ''}
        style={{ marginLeft: '12px', marginRight: '12px' }}
        disabled={props.readOnly}
      >
        {(options || []).map(option => (
          <MenuItem key={option} value={option} name={name}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {/* <ErrorMessage name={name} component="div" /> */}
    </div>
    )}
    </Subscribe>
  );
}
