import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu, MenuItem } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';

interface Istate {
  value: number;
  rightMenuOpen: boolean;
  anchorEl: HTMLElement | null;
}

class NavMenu extends React.Component<{}, Istate> {
  public state = {
    value: 0,
    rightMenuOpen: false,
    anchorEl: null
  };

  public render() {
    return (
      <div>
        <AppBar position="static" color="primary">
          <Toolbar>
            <IconButton color="inherit" aria-label="Padding" />
            <IconButton color="inherit" aria-label="Padding" />
            <h3
              style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}
            >
              Well Delivery Process Tool
            </h3>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.openRightMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={this.state.anchorEl}
              open={this.state.rightMenuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              onClose={this.handleMenuClose}
              getContentAnchorEl={null}
            >
              <MenuItem onClick={this.handleMenuClose}>My Settings</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>Well List</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>Generate Report</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
  private handleChange = (event: any, newValue: number) => {
    this.setState({ value: newValue });
  }
  private openRightMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({
      rightMenuOpen: !this.state.rightMenuOpen,
      anchorEl: event.currentTarget
    });
  }
  private handleMenuClose = () => {
    this.setState({ rightMenuOpen: false });
  }
}

export default NavMenu;
