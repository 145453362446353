import { HotTable } from '@handsontable/react';
import React, { FormEvent } from 'react';
import { IappInputGroup, Igrid, renderFactory } from '../../types/model';
import { Button, Grid, CssBaseline } from '@material-ui/core';
import { Subscribe } from 'unstated';
import FormState from '../../state/uFormState';
import _ from 'lodash';
import { formState } from '../FormView';

interface Iprops {
  grid: Igrid['grid'];
  readOnly: boolean;
}

// interface Istate {
//   colWidths: number[];
// };

const startingWidth = 1500;
const rowLabelWidth = 49;

export default class FormGrid extends React.Component<Iprops> {
  public columnHeaders: string[];
  public colWidths: number[];
  public columns: any[];
  public data: Array<{ [key: string]: any }> = [{}];
  private hot: React.RefObject<HotTable>;
  private container: React.RefObject<HTMLDivElement>;
  private selR = 0;
  private schema: {};
  private selC = 0;
  private numCols: number;
  constructor(props: Iprops) {
    super(props);
    this.hot = React.createRef();
    this.container = React.createRef();
    this.columnHeaders = props.grid!.columns.map((c) => c.label || c.key);
    this.colWidths = props.grid!.columns.map((c) => c.relWidth * 5);
    this.numCols = props.grid!.columns.length;

    this.schema = props.grid!.columns.reduce(
      (p: { [key: string]: string }, c) => {
        p[c.key] = '';
        return p;
      },
      {}
    );
    // need to load state like this because hot handles it's own state
    const storeState = formState.getFormDataFromStore();
    this.data = storeState[this.props.grid.key]
      ? _.cloneDeep(storeState[this.props.grid.key])
      : [];
    this.columns = props.grid!.columns.map((c) => {
      let obj: any = { data: c.key };
      if (c.options) {
        obj = {
          ...obj,
          type: 'autocomplete',
          source: c.options,
          strict: false,
          filter: false
        };
      }
      if (c.render) {
        obj.renderer = renderFactory[c.render.funcKey](...c.render.params).bind(
          this
        );
      }
      return obj;
    });
  }

  public render() {
    return (
      <div ref={this.container}>
        <CssBaseline />
        <div style={{ marginLeft: 12 }}>
          <HotTable
            // manualRowResize={true}
            data={this.data}
            manualColumnResize={true}
            minSpareRows={1}
            dataSchema={this.schema}
            columns={this.columns}
            colHeaders={this.columnHeaders}
            rowHeaders={true}
            contextMenu={[
              'row_above',
              'row_below',
              'remove_row',
              'undo',
              'redo',
              'cut',
              'copy'
            ]}
            stretchH="all"
            wordWrap
            // autoRowSize NO!!!!
            colWidths={this.colWidths}
            afterSelection={this.hotChange}
            afterChange={this.handleChange}
            ref={this.hot}
            readOnly={this.props.readOnly}
          />
        </div>
        {!this.props.readOnly ? (
          <Grid container justify="flex-end">
            <Button onClick={this.addRow}>Add Row</Button>
            <Button onClick={this.deleteRow}>Delete Row</Button>
          </Grid>
        ) : null}
      </div>
    );
  }

  private handleChange = () => {
    if (!this.hot || !this.hot.current) return;
    formState.setField(this.props.grid.key, this.data);
  }
  private addRow = () => {
    const hot = this.hot!.current!.hotInstance;
    hot.alter('insert_row', this.selR + 1);
    hot.selectCell(this.selR + 1, 0);
    setTimeout(hot.render.bind(hot), 1);
  }
  private deleteRow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    const hot = this.hot!.current!.hotInstance;
    hot.alter('remove_row', this.selR);
    hot.selectCell(this.selR, 0);
    hot.render();
  }
  private hotChange = (r: number, c: number) => {
    this.selR = r;
    this.selC = c;
  }
}
