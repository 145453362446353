import _ from 'lodash';
import FormGrid from '../components/formTypes/FormGrid';
import numeral from 'numeral';

export const renderFactory: any = {
  calcCum: (cumProp: string) => {
    return function (
      this: FormGrid,
      hotInstance: Handsontable,
      td: HTMLTableDataCellElement,
      row: number,
      col: number,
      prop: number | string
    ) {
      const data = this.data;
      if (!data[row][cumProp]) return td;
      const val =
        Number(data[row][cumProp]) +
        (row > 0 ? Number(data[row - 1][prop]) : 0);
      data[row][prop] = !isNaN(val) ? val : null;
      td.innerHTML = numeral(val).format('$0,0');
      td.classList.add('htDimmed');
      return td;
    };
  },
  dollarInput: () => {
    return function (
      this: FormGrid,
      hotInstance: Handsontable,
      td: HTMLTableDataCellElement,
      row: number,
      col: number,
      prop: number | string,
      val: number | string
    ) {
      if (val === '') return td;
      td.innerHTML = numeral(val).format('$0,0');
      return td;
    };
  }
};

interface IBasicDef {
  key: string;
  label: string;
}

export interface IdataField {
  label?: string;
  key: string;
  options?: string[];
}

export interface IshortText {
  key: string;
  label?: string;
  options?: string[];
}

class Short implements IshortText {
  public label: string;
  constructor(public key: string, labelIn?: string, public options?: string[]) {
    this.label = labelIn || _.startCase(key);
  }
}

export interface IgridCol extends IshortText {
  relWidth: number; // 1-5 relative widths
  render?: {
    funcKey: string;
    params: any[];
  };
}

// tslint:disable-next-line max-classes-per-file
class ShortCol extends Short implements IgridCol {
  public render?: {
    funcKey: string;
    params: any[];
  };
  constructor(
    public key: string,
    public relWidth: number,
    labelIn?: string,
    public options?: string[]
  ) {
    super(key, labelIn, options);
  }
}

export interface IgridShape {
  key: string;
  columns: Array<{
    key: string;
    label?: string;
    options?: string[];
  }>;
}

export interface Icategory {
  key: string;
  label?: string;
  minPhase?: number;
  maxPhase?: number;
  inputGroups: IappInputGroup[];
}

export interface IshortField {
  label?: string;
  shortField: IshortText[];
}

export interface IlongText {
  label?: string;
  longText: IshortText[];
}

export interface Igrid {
  label?: string;
  grid: {
    key: string;
    columns: IgridCol[];
  };
}

export interface IppData {
  key: string;
  label: string;
  style?: {};
  data: number[][];
}
export interface IporePressure {
  porePressure: {
    key: string;
  };
}

export type IappInputGroup = IshortField | IlongText | Igrid | IporePressure;

export function getLabel(obj: { key: string; label?: string }) {
  return obj.label || _.startCase(obj.key);
}

export const model: Icategory[] = [
  {
    key: 'general',
    inputGroups: [
      {
        shortField: [
          new Short('wellName'),
          new Short('field'),
          new Short('block'),
          new Short('rig'),
          new Short('wellType', undefined, [
            'Exploration',
            'Appraisal',
            'Production',
            'Abandonment',
            'Intervention'
          ]),
          new Short('anticipatedStart')
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
          // new short('wellName'),
          // new short('field'),
          // new short('block'),
          // new short('rig'),
        ]
      }
    ]
  },
  {
    key: 'team',
    inputGroups: [
      {
        grid: {
          key: 'teamGrid',
          columns: [
            new ShortCol('name', 3),
            new ShortCol('email', 3),
            new ShortCol('position', 3, undefined, [
              'Well Delivery Manager',
              'Subsurface Manager',
              'Asset Manager',
              'Drilling Engineer',
              'Completions Engineer',
              'Superintendent',
              'Geologist',
              'Reservoir Engineer',
              'Geohazards Specialist'
            ])
          ]
        }
      }
    ]
  },
  {
    key: 'targets',
    minPhase: 2,
    maxPhase: 4,
    inputGroups: [
      {
        longText: [
          {
            key: 'nonReservoirTargets'
          }
        ]
      },
      {
        grid: {
          key: 'nonReservoirTargetsGrid',
          columns: [
            new ShortCol('name', 3),
            new ShortCol('depthTVD', 3),
            new ShortCol('centerX', 3),
            new ShortCol('centerY', 3),
            new ShortCol('angle', 3),
            new ShortCol('targetSize', 3)
          ]
        }
      },
      {
        longText: [
          {
            key: 'reservoirTargets'
          }
        ]
      },
      {
        grid: {
          key: 'reservoirTargetsGrid',
          columns: [
            new ShortCol('name', 3),
            new ShortCol('depthTVD', 3),
            new ShortCol('centerX', 3),
            new ShortCol('centerY', 3),
            new ShortCol('angle', 3),
            new ShortCol('targetSize', 3)
          ]
        }
      }
    ]
  },
  {
    ...new Short('lithology'),
    minPhase: 2,
    inputGroups: [
      {
        grid: {
          ...new Short('lithologyTable'),
          columns: [
            new ShortCol('name', 3),
            new ShortCol('topTVDSS', 2),
            new ShortCol('uncertainty', 2),
            new ShortCol('thickness', 2),
            new ShortCol('description', 5),
            new ShortCol('hydrocarbons', 3)
          ]
        }
      }
    ]
  },
  {
    key: 'subsurfaceHazards',
    minPhase: 2,
    inputGroups: [
      {
        shortField: [
          {
            key: 'co2',
            label: 'Max CO2 Percentage'
          },
          {
            key: 'h2s',
            label: 'Max H2S Percentage'
          },
          {
            key: 'maxPressureGradient'
          },
          {
            key: 'minPressureGradient'
          }
        ]
      },
      {
        longText: [
          {
            key: 'overburdenDescription'
          },
          {
            key: 'reservoirDescription'
          }
        ]
      },
      {
        grid: {
          key: 'hazardsTable',
          columns: [
            new ShortCol('type', 3, undefined, [
              'Contaminant',
              'Hydrocarbon',
              'Fault',
              'Unstable Fm'
            ]),
            new ShortCol('depthTVD', 3),
            new ShortCol('comments', 5)
          ]
        }
      }
    ]
  },
  {
    key: 'porePressure',
    minPhase: 2,
    inputGroups: [
      {
        porePressure: {
          key: 'porePressure'
        }
        // porePressure: [
        //   {
        //     ...new Short('ppMid', 'PP (mid)'),
        //     style: {
        //       color: 'red'
        //     }
        //   },
        //   {
        //     ...new Short('fgMid', 'FG (mid)'),
        //     style: {
        //       color: 'blue'
        //     }
        //   }
        // ]
      }
    ]
  },
  {
    key: 'costs',
    inputGroups: [
      {
        shortField: [
          new Short('contingencyPercentage'),
          new Short('costEstimateLevel', undefined, [
            'Level 1',
            'Level 2',
            'Level 3',
            'Level 4'
          ])
        ]
      },
      {
        grid: {
          ...new Short('wellCosts'),
          columns: [
            { key: 'phase', label: 'Phase', relWidth: 3 },
            { key: 'depth', label: 'Depth', relWidth: 2 },
            {
              key: 'sectionCost',
              label: 'Section Cost',
              relWidth: 3,
              render: {
                funcKey: 'dollarInput',
                params: []
              }
            },
            {
              key: 'cumulativeCost',
              label: 'Cumulative Cost',
              relWidth: 3,
              render: {
                funcKey: 'calcCum',
                params: ['sectionCost']
              }
            }
          ]
        }
      }
    ]
  },
  {
    key: 'wellDesign',
    inputGroups: [
      {
        grid: {
          ...new Short('wellCosts'),
          columns: [
            new ShortCol('phase', 3),
            new ShortCol('depth', 3),
            new ShortCol('holeSize', 3),
            new ShortCol('casingSize', 3),
            new ShortCol('casingWeight', 3),
            new ShortCol('casingGrade', 3),
            new ShortCol('driftID', 3)
          ]
        }
      }
    ]
  },
  {
    key: 'lessonsLearned',
    minPhase: 4,
    maxPhase: 5,
    inputGroups: [
      {
        grid: {
          ...new Short('lessonsTable'),
          columns: [
            new ShortCol('phase', 1),
            new ShortCol('whatWeDid', 3),
            new ShortCol('whatWeShouldHaveDone', 3),
            new ShortCol('whoToTell', 3)
          ]
        }
      }
    ]
  }
];

/*
data structure
well: {
  key: string,
  name: string,
  data: {
    general: {
      wellName: string,
      field: string,
      block: string,
      ...
    }
    porePressure: [
      {
        label: string,
        style: {},
        data: number[][],
      },
      {
        label: string,
        style: {},
        number[][],
      },
    ]
  }
}
*/
