import { Container } from 'unstated';

interface Istate {
  [propName: string]: any;
}

export default class DataStore extends Container<any> {
  public state: Istate = {};
  constructor(populateFakes: boolean = true) {
    super();
    if (populateFakes) {
      // simulate an API load
      setTimeout(() => {
        this.setState(mocks);
      },         100);
    }
  }
  public setCategory = (category: string, values: {}) => {
    return this.setState({ [category]: values });
  }
}

const mocks = {
  metaData: {
    approvedPhase: 2
  },
  general: {
    wellName: 'Bertha1',
    field: 'Hydra',
    block: 'West 24-6',
    rig: 'Deepwater Example',
    wellType: 'Production',
    anticipatedStart: 'Q2 2021'
  },
  team: {
    teamGrid: [
      {
        name: 'Ada P. Hughes',
        email: 'AdaPHughes@energyco.com',
        position: 'Well Delivery Manager'
      },
      {
        name: 'William M. Walthall',
        email: 'WilliamMWalthall@energyco.com',
        position: 'Asset Manager'
      },
      {
        name: 'Karen Dodson',
        email: 'karen.dodson@energyco.com',
        position: 'Superintendent'
      },
      {
        name: 'Edward Davis',
        email: 'edward.davis@energyco.com',
        position: 'Drilling Engineer'
      },
      {
        name: 'Karen Wentworth',
        email: 'karen.wentworth@energyco.com',
        position: 'Completions Engineer'
      },
      {
        name: 'Stacey Blagg',
        email: 'stacy.blagg@energyco.com',
        position: 'Reservoir Engineer'
      },
      {
        name: 'Karina Cheatham',
        email: 'karina.cheatham@energyco.com',
        position: 'Geologist'
      },
      {
        name: 'Hugo Seltzer',
        email: 'hugo.seltzer@energyco.com',
        position: 'Geohazards Specialist'
      },
      {
        name: 'Assia Almonde',
        email: 'assia.almonde@energyco.com',
        position: 'Pore Pressure Specialist'
      }
    ]
  },
  targets: {
    nonReservoirTargets: 'N/A - no overburden targets required.',
    reservoirTargets:
      'The upper and lower Paysand targets are vertically stacked on a slight NE path',
    reservoirTargetsGrid: [
      {
        name: 'Upper  Paysand',
        depthTVD: '6010',
        centerX: '234235',
        centerY: '128743',
        angle: '5°',
        targetSize: "200'x200' box"
      },
      {
        name: 'Lower  Paysand',
        depthTVD: '6350',
        centerX: '234255',
        centerY: '128752',
        angle: '5°',
        targetSize: "200'x200' box"
      },
      {
        name: '',
        depthTVD: '',
        centerX: '',
        centerY: '',
        angle: '',
        targetSize: ''
      }
    ]
  },
  porePressure: {
    porePressure: [
      {
        key: 'ppMid',
        label: 'PP (mid)',
        style: { color: 'red' },
        data: [
          [100, 43.3, '8.34'],
          [1000, 433, '8.34'],
          [1100, 500, '8.76'],
          [1200, 550, '8.83'],
          [1300, 600, '8.89'],
          [1400, 700, '9.63'],
          [1500, 800, '10.28'],
          [2000, 1100, 10.6],
          [2000, 1200, 11.56],
          [2100, 1250, 11.47],
          [2100, 1150, 10.55],
          [2400, 1290, 10.36]
        ]
      },
      {
        key: 'fgMid',
        label: 'FG (mid)',
        style: { color: 'blue' },
        data: [
          [100, 63.3, '12.20'],
          [1000, 633, '12.20'],
          [1100, 800, '14.01'],
          [1200, 900, '14.45'],
          [1300, 1000, '14.82'],
          [1400, 1075, '14.79'],
          [1500, 1125, '14.45'],
          [1600, 1175, 14.15],
          [2000, 1400, 13.49],
          [2400, 1700, 13.65]
        ]
      }
    ]
  },
  costs: {
    contingencyPercentage: '23%',
    costEstimateLevel: 'Level 3',
    wellCosts: [
      {
        phase: 'Mobilization',
        depth: '0',
        sectionCost: '3000000',
        cumulativeCost: '$3,000,000'
      },
      {
        phase: '13-5/8" Surface Section',
        depth: '3000',
        sectionCost: '1400500',
        cumulativeCost: '$4,400,500'
      },
      {
        phase: '9-5/8" Intermediate Section',
        depth: '6000',
        sectionCost: '2300400',
        cumulativeCost: '$6,700,900'
      },
      {
        phase: '7" Production Section',
        depth: '9000',
        sectionCost: '5000000',
        cumulativeCost: '$11,700,900'
      },
      { phase: '', depth: '', sectionCost: '', cumulativeCost: '' }
    ]
  },
  subsurfaceHazards: {
    co2: '3%-5% in reservoir',
    h2s: 'n/a',
    maxPressureGradient: '11 ppg',
    minPressureGradient: '8.35 ppg'
  },
  wellDesign: {
    wellCosts: [
      {
        phase: '13-5/8"  Surface Section',
        depth: '3000',
        holeSize: '17-1/2"',
        casingSize: '13-5/8"',
        casingWeight: '85#',
        casingGrade: 'K-55',
        driftID: '12.25'
      },
      {
        phase: '9-5/8"  Intermediate Section',
        depth: '6000',
        holeSize: '12-1/4"',
        casingSize: '9-5/8"',
        casingWeight: '53#',
        casingGrade: 'P-110',
        driftID: '8.5'
      },
      {
        phase: '7"  Production Section',
        depth: '9000',
        holeSize: '8-1/2"',
        casingSize: '7"',
        casingWeight: '29#',
        casingGrade: 'P-110',
        driftID: '6'
      },
      {
        phase: '',
        depth: '',
        holeSize: '',
        casingSize: '',
        casingWeight: '',
        casingGrade: '',
        driftID: ''
      }
    ]
  },
  lithology: {
    lithologyTable: [
      {
        name: 'Brugg',
        topTVDSS: '300',
        uncertainty: '5',
        thickness: '2000',
        description: 'unconsolidated silt and shale',
        hydrocarbons: 'none'
      },
      {
        name: 'Liva',
        topTVDSS: '2300',
        uncertainty: '15',
        thickness: '1600',
        description: 'claystone',
        hydrocarbons: 'none'
      },
      {
        name: 'Red',
        topTVDSS: '3900',
        uncertainty: '22',
        thickness: '2000',
        description: 'siltstone with streaks of sand',
        hydrocarbons: 'none'
      },
      {
        name: 'Fountain',
        topTVDSS: '5900',
        uncertainty: '25',
        thickness: '4000',
        description: 'siltstone with streaks of sand',
        hydrocarbons: 'possible gas in sand streaks'
      },
      {
        name: 'Ersatz',
        topTVDSS: '9900',
        uncertainty: '40',
        thickness: '175',
        description: 'clean reservoir sand',
        hydrocarbons: 'gas'
      },
      {
        name: '',
        topTVDSS: '',
        uncertainty: '',
        thickness: '',
        description: '',
        hydrocarbons: ''
      }
    ]
  }
};

/* {
  general: {
    wellName: 'Bertha1',
    field: 'Hydra',
    block: 'West 24-6',
    rig: 'Deepwater Example',
    wellType: 'Production',
    anticipatedStart: 'Q2 2021'
  },
  team: {
    teamGrid: [
      {
        name: 'Ada P. Hughes',
        email: 'AdaPHughes@energyco.com',
        position: 'Well Delivery Manager'
      },
      {
        name: 'William M. Walthall',
        email: 'WilliamMWalthall@energyco.com',
        position: 'Asset Manager'
      },
      {
        name: 'Karen Dodson',
        email: 'karen.dodson@energyco.com',
        position: 'Superintendent'
      },
      {
        name: 'Edward Davis',
        email: 'edward.davis@energyco.com',
        position: 'Drilling Engineer'
      },
      {
        name: 'Karen Wentworth',
        email: 'karen.wentworth@energyco.com',
        position: 'Completions Engineer'
      },
      {
        name: 'Stacey Blagg',
        email: 'stacy.blagg@energyco.com',
        position: 'Reservoir Engineer'
      },
      {
        name: 'Karina Cheatham',
        email: 'karina.cheatham@energyco.com',
        position: 'Geologist'
      },
      {
        name: 'Hugo Seltzer',
        email: 'hugo.seltzer@energyco.com',
        position: 'Geohazards Specialist'
      }
    ]
  },
  targets: {
    nonReservoirTargets: 'N/A - no overburden targets required.',
    reservoirTargets:
      'The upper and lower Paysand targets are vertically stacked on a slight NE path',
    reservoirTargetsGrid: [
      ['Upper Paysand', '6010', '234235', '128743', '5°', "200'x200' box"],
      ['Lower Paysand', '6350', '234255', '128752', '5°', "200'x200' box"]
    ]
  },
  porePressure: {
    porePressure: [
      {
        key: 'ppMid',
        label: 'PP (mid)',
        style: { color: 'red' },
        data: [
          [100, 43.3, '8.34'],
          [1000, 433, '8.34'],
          [1100, 500, '8.76'],
          [1200, 550, '8.83'],
          [1300, 600, '8.89'],
          [1400, 700, '9.63'],
          [1500, 800, '10.28'],
          [2000, 1100, 10.6],
          [2000, 1200, 11.56],
          [2100, 1250, 11.47],
          [2100, 1150, 10.55],
          [2400, 1290, 10.36]
        ]
      },
      {
        key: 'fgMid',
        label: 'FG (mid)',
        style: { color: 'blue' },
        data: [
          [100, 63.3, '12.20'],
          [1000, 633, '12.20'],
          [1100, 800, '14.01'],
          [1200, 900, '14.45'],
          [1300, 1000, '14.82'],
          [1400, 1075, '14.79'],
          [1500, 1125, '14.45'],
          [1600, 1175, 14.15],
          [2000, 1400, 13.49],
          [2400, 1700, 13.65]
        ]
      }
    ]
  },
  costs: {
    contingencyPercentage: '23%',
    costEstimateLevel: 'Level 3',
    wellCosts: [
      ['Mobilization', '0', '$3000000', '$3000000'],
      ['13-5/8" Surface Section', '3000', '$1400500', '$4400500'],
      ['9-5/8" Intermediate Section', '6000', '$2300400', '$6700900'],
      ['7" Production Section', '9000', '5000000', '$11700900']
    ]
  },
  subsurfaceHazards: {
    co2: '3%-5% in reservoir',
    h2s: 'n/a',
    maxPressureGradient: '11 ppg',
    minPressureGradient: '8.35 ppg'
  },
  wellDesign: {
    wellCosts: [
      [
        '13-5/8" Surface Section',
        '3000',
        '17-1/2"',
        '13-5/8"',
        '85#',
        'K-55',
        '12.25'
      ],
      [
        '9-5/8" Intermediate Section',
        '6000',
        '12-1/4"',
        '9-5/8"',
        '53#',
        'P-110',
        '8.5'
      ],
      ['7" Production Section', '9000', '8-1/2"', '7"', '29#', 'P-110', '6']
    ]
  },
  lithology: {
    lithologyTable: [
      ['Brugg', '300', '5', '2000', 'unconsolidated silt and shale', 'none'],
      ['Liva', '2300', '15', '1600', 'claystone', 'none'],
      ['Red', '3900', '22', '2000', 'siltstone with streaks of sand', 'none'],
      [
        'Fountain',
        '5900',
        '25',
        '4000',
        'siltstone with streaks of sand',
        'possible gas in sand streaks'
      ],
      ['Ersatz', '9900', '40', '175', 'clean reservoir sand', 'gas']
    ]
  }
};*/
