import React from 'react';
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import LayersIcon from '@material-ui/icons/Layers';
import WarningIcon from '@material-ui/icons/Warning';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import BuildIcon from '@material-ui/icons/Build';
import StarsIcon from '@material-ui/icons/Stars';
import SchoolIcon from '@material-ui/icons/School';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FormView from './FormView';
import { model, getLabel } from '../types/model';
import {
  Fab,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper
} from '@material-ui/core';
import DataStore from '../state/uDataStore';
import { Provider, Subscribe } from 'unstated';
import Approvals from './formTypes/Approvals';
import _ from 'lodash';

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      // overflowY: 'hidden',
      bottom: 0
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      bottom: 0
    },
    drawerPaper: {
      top: 'auto',
      width: drawerWidth
    },
    toolbar: {
      minHeight: 56
    },
    content: {
      flexGrow: 1,
      backgroundColor: '#fafafa',
      padding: 2 * 3
      // overflowY: 'auto',
    },
    indicator0: {
      backgroundColor: 'red'
    },
    indicator1: {
      backgroundColor: 'yellow'
    },
    indicator2: {
      backgroundColor: 'green'
    },
    indicator3: {
      backgroundColor: 'lightblue'
    },
    indicator4: {
      backgroundColor: 'purple'
    }
  });

const phaseColors = ['#FF6060', '#FCFF60', '#4DCC51', 'lightblue', '#9560FF'];
const phaseNames = [
  'Assess Opportunity',
  'Select Concept',
  'Detailed Design',
  'Execute',
  'Review'
];
const mainBorder = (phase: number) => {
  return { borderTop: `5px solid ${phaseColors[phase]}` };
};

function chooseAltForm(key: string) {
  const formObj: { [index: string]: JSX.Element } = {
    approvals: <Approvals />
  };
  // tslint:disable-next-line variable-name
  // const AltForm = formObj[key];
  // return <AltForm />;
  return formObj[key];
}

export const dataStore = new DataStore();

const icons: { [key: string]: JSX.Element } = {
  general: <SettingsIcon />,
  team: <PeopleIcon />,
  lithology: <LayersIcon />,
  subsurfaceHazards: <WarningIcon />,
  porePressure: <WhatsHotIcon />,
  costs: <MoneyIcon />,
  wellDesign: <BuildIcon />,
  targets: <StarsIcon />,
  lessonsLearned: <SchoolIcon />
};

interface Iprops extends WithStyles<typeof styles, true> {}

interface Istate {
  key: string;
  phase: number;
  storePhase: number;
}

class PermanentDrawerLeft extends React.Component<Iprops, Istate> {
  constructor(props: Iprops) {
    super(props);
    this.state = {
      key: model[0].key,
      phase: 2,
      storePhase: 0
    };
    dataStore.subscribe(() => {
      if (!_.has(dataStore.state, 'metaData.approvedPhase')) {
        return;
      }
      const approved = dataStore.state.metaData.approvedPhase;
      this.setState({ storePhase: approved });
    });
  }

  public changeForm(key: string) {
    this.setState({ key });
  }

  public render() {
    const { classes } = this.props;
    const phaseCats = model.filter((cat) => {
      return (
        (cat.minPhase || 0) <= this.state.phase + 1 &&
        (cat.maxPhase || 99) >= this.state.phase + 1
      );
    });
    return (
      <div className={classes.root} style={mainBorder(this.state.phase)}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          {/* <div className={classes.toolbar} /> */}
          <FormControl style={{ margin: '5px' }}>
            <InputLabel htmlFor="phase-selection">Select Phase</InputLabel>
            <Select
              value={this.state.phase}
              inputProps={{ name: 'phase', id: 'phase-selection' }}
              onChange={this.handlePhaseChange}
            >
              {phaseNames.map((v, i) => (
                <MenuItem key={i} value={i}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider />
          <List>
            {phaseCats.map((cat, index) => (
              <ListItem
                button
                key={cat.key}
                onClick={() => this.changeForm(cat.key)}
                selected={this.state.key === cat.key}
              >
                <ListItemIcon>{icons[cat.key]}</ListItemIcon>
                <ListItemText primary={getLabel(cat)} />
              </ListItem>
            ))}
          </List>
          <Divider />
          {this.state.phase === this.state.storePhase ? (
            <List>
              <ListItem
                button
                key="approvals"
                onClick={() => this.changeForm('approvals')}
                selected={this.state.key === 'approvals'}
              >
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText primary="Approvals" />
              </ListItem>
            </List>
          ) : null}
        </Drawer>
        <main className={classes.content}>
          <Provider inject={[dataStore]}>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12}>
                {this.state.storePhase !== this.state.phase ? (
                  <Paper
                    style={{
                      margin: 12,
                      padding: 12,
                      backgroundColor: 'lightgray'
                    }}
                  >
                    {`Viewing ${
                      phaseNames[this.state.phase]
                    } phase in read only mode`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {_.some(phaseCats, { key: this.state.key }) ? (
                  <FormView
                    key={this.state.key}
                    modelKey={this.state.key}
                    readOnly={this.state.storePhase !== this.state.phase}
                  />
                ) : (
                  chooseAltForm(this.state.key)
                )}
              </Grid>
            </Grid>
          </Provider>
        </main>
      </div>
    );
  }
  private handlePhaseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const phase = Number(event.target.value);
    const phaseCats = model.filter((cat) => {
      return (
        (cat.minPhase || 0) <= phase + 1 && (cat.maxPhase || 99) >= phase + 1
      );
    });
    const ind = _.findIndex(phaseCats, { key: this.state.key });
    let extra = {};
    if (ind === -1) {
      extra = { key: phaseCats[0].key };
    }
    this.setState({ phase, ...extra });
  }
}

export default withStyles(styles)(PermanentDrawerLeft);
