import React from 'react';
import {
  Fab,
  Modal,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { useTheme } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    fabbutton: {
      position: 'fixed',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2
    }
  });

interface Iprops extends WithStyles<typeof styles, true> {}

interface Istate {
  open: boolean;
}

class QuestionModal extends React.Component<Iprops, Istate> {
  public state = {
    open: false
  };
  // public theme: { spacing: { unit: number } } = useTheme();
  public render() {
    return (
      <div>
        <Fab
          className={this.props.classes.fabbutton}
          color="primary"
          onClick={() => this.setState({ open: true })}
        >
          <HelpIcon />
        </Fab>
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title">
            Well Delivery Process Tool Demo
          </DialogTitle>
          <DialogContent>
            <p>
              This is a prototype demo of a tool for managing critical well data
              in the context of a modern well delivery process. It does not yet
              have a full feature set implemented, but the set which is
              implemented is intended to highlight the user interface and
              workflow of the application. The Well Delivery Process is a common
              concept in the oil industry, but is often weighted down by
              cumbersome and inefficient tools. The WDP tool is a conceptual tool
              to enable input, sharing, approvals, and change management in a
              style that integrates into the workflow of those contributing to
              the WDP so that it becomes an aid to identify and communicate the
              required WDP elements rather than an annoying check-the-box exercise.
            </p>
            <p>Operating companies have different needs, and we would love to hear yours.
              Please contact connor@welldeliveryprocess.com to discuss how we can help you.
            </p>
            <p>
              Planned feature additions include:
            </p>
            <ul>
              <li>Additional data fields, with supporting auto-calculations and graphs</li>
              <li>Integrated approvals by user role</li>
              <li>Data traceability to identify which items have changed from previous phase</li>
              <li>Unit management system</li>
              <li>Enforce data fields as "Required" and link to relevant standard.</li>
              <li>Beautiful PDF reports for any phase.</li>
              <li>Data branching and interitance to accomodate campaign phases that lead to 
                individual wells.</li>
            </ul>
            <p>The WDP tool is founded by a Well Engineer with 12 years of experience with a major
              operator, who is now focused on making the software tools to solve the pain points
              encountered in the oil industry.  Our mission is to make tools that are pleasant to
              use and actually aid the workflow of the technical staff, rather than solely being a
              mandate from mangament to meet assurance requirements.</p>
            <p>
              The data contained in this sample is purely fabricated and does
              not refer to any actual prospect.
            </p>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(QuestionModal);
