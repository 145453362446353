import React from 'react';
import { IporePressure, IppData } from '../../types/model';
import { Grid, TextField, MenuItem, Select, Button } from '@material-ui/core';
import { Scatter } from 'react-chartjs-2';
import _ from 'lodash';
import { PorePressureGrid } from './PorePressureGrid';
import { formState } from '../FormView';

const baseDataSet = {
  data: [{ x: 0, y: 0 }],
  label: 'Pore Pressure (mid)',
  borderColor: 'red',
  borderWidth: 1,
  pointRadius: 0,
  pointHoverRadius: 5,
  tension: 0,
  fill: false,
  showLine: true
};

type tds = typeof baseDataSet;

const makeDataSet = (ppData: IppData): tds => {
  const ds: tds = _.clone(baseDataSet);
  ds.label = ppData.label;
  ds.data = convertXY(ppData.data);
  ds.borderColor = _.get(ppData, 'style.color', 'black');
  return ds;
};

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'TVDRKB (ft)'
        },
        ticks: {
          reverse: true,
          start: 0
        }
      }
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'ppg'
        }
      }
    ]
  }
};

const defInfo: IppData[] = [
  {
    key: 'ppMid',
    label: 'PP (mid)',
    style: {
      color: 'red'
    },
    data: [
      // [100, 43.3, 8.34],
      // [1000, 433, 8.34],
      // [1100, 500, 8.76],
      // [1200, 550, 8.83],
      // [1300, 600, 8.89],
      // [1400, 700, 9.63],
      // [1500, 800, 10.28],
      // [2000, 1100, 10.6],
      // [2000, 1200, 11.56],
      // [2100, 1250, 11.47],
      // [2100, 1150, 10.55],
      // [2400, 1290, 10.36]
    ],
  },
  {
    key: 'fgMid',
    label: 'FG (mid)',
    style: {
      color: 'blue'
    },
    data: [
      // [100, 63.3, 12.2],
      // [1000, 633, 12.2],
      // [1100, 800, 14.01],
      // [1200, 900, 14.45],
      // [1300, 1000, 14.82],
      // [1400, 1075, 14.79],
      // [1500, 1125, 14.45],
      // [1600, 1175, 14.15],
      // [2000, 1400, 13.49],
      // [2400, 1700, 13.65]
    ],
  }
];

const convertXY = (data: number[][]) => {
  return data.map(row => {
    return { x: row[2], y: row[0] };
  });
};

interface Iprops {
  keyId: string;
  readOnly: boolean;
}

interface Istate {
  dataIndex: number;
}

export class PorePressure extends React.Component<Iprops, Istate> {
  private chart: React.RefObject<Scatter>;
  private debUpdate: (data: number[][]) => void;
  private dataSets: tds[];
  private gridData: IppData[];
  constructor(props: Iprops) {
    super(props);
    this.chart = React.createRef();
    this.debUpdate = _.debounce(this.updateChartData.bind(this), 500);
    // const fs = formState.getFormDataFromStore()[this.props.keyId];
    this.gridData = (formState.getFormDataFromStore()[this.props.keyId] || defInfo);
    this.dataSets = this.gridData.map(
      (p: IppData) => makeDataSet(p)
    );
    this.state = {
      dataIndex: 0
    };
  }

  public render() {
    return (
      <div>
        <Grid container spacing={8}>
          <Grid item sm={12} md={6}>
            <Select
              // fullWidth
              value={this.state.dataIndex}
              style={{ margin: '0 0 8px 8px' }}
              onChange={(event: any) => {
                this.setState({ dataIndex: event.target.value });
              }}
            >
              {(this.gridData).map((option, i) => (
                <MenuItem key={i} value={i}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <PorePressureGrid
              key={this.state.dataIndex}
              onUpdate={this.debUpdate}
              ppData={this.gridData[this.state.dataIndex]}
              readOnly={this.props.readOnly}
            />
            <Button>Add Pressure Data</Button>
          </Grid>
          <Grid item sm={12} md={6}>
            <Scatter
              height={400}
              ref={this.chart}
              data={{ datasets: this.dataSets }}
              options={options}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  private updateChartData = (data: number[][]) => {
    if (!this.chart || !this.chart.current) return;
    const chart = this.chart.current.chartInstance;
    const chartData = data.map(row => {
      return { x: row[2], y: row[0] };
    });
    formState.setField(this.props.keyId, this.gridData);
    chart.data.datasets[this.state.dataIndex].data = chartData;
    chart.update();
  }
}
