import React from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import {
  IappInputGroup,
  IdataField,
  IshortField,
  IlongText,
  Igrid,
  IporePressure
} from '../types/model';
import FormTextElement from './formTypes/FormTextElement';
import FormGrid from './formTypes/FormGrid';
import FormMultiLine from './formTypes/FormMultiLine';
import { PorePressure } from './formTypes/PorePressure';

interface Iprops {
  inputGroup: IappInputGroup;
  readOnly: boolean;
}

export function FieldChooser({ inputGroup, readOnly }: Iprops) {
  if ((inputGroup as IshortField).shortField) {
    return (
      <Grid item xs={12}>
        <Grid container spacing={16}>
          {_.times(2, (gi) => (
            <Grid key={gi} item xs={6} sm={6}>
              {(inputGroup as IshortField)
                .shortField!.filter((o, i, { length }) =>
                  gi ? i >= length / 2 : i < length / 2
                )
                .map((o: IdataField) => (
                  <FormTextElement
                    name={o.key}
                    key={o.key}
                    label={o.label}
                    options={o.options}
                    readOnly={readOnly}
                  />
                ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
  if ((inputGroup as IlongText).longText) {
    return (
      <Grid item xs={12}>
        {(inputGroup as IlongText).longText!.map((o: IdataField) => (
          <FormMultiLine name={o.key} key={o.key} label={o.label} readOnly={readOnly}/>
        ))}
      </Grid>
    );
  }
  if ((inputGroup as Igrid).grid) {
    return (
      <Grid item xs={12}>
        <FormGrid grid={(inputGroup as Igrid).grid} readOnly={readOnly}/>
      </Grid>
    );
  }
  if ((inputGroup as IporePressure).porePressure) {
    return (
      <Grid item xs={12}>
        <PorePressure keyId={(inputGroup as IporePressure).porePressure.key} readOnly={readOnly}/>
      </Grid>
    );
  }
  return null;
}
