import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { model, IdataField, Icategory } from '../../types/model';
import FormTextElement from './FormTextElement';
import { FieldChooser } from '../FieldChooser';
import { Subscribe, Provider } from 'unstated';
import DataStore from '../../state/uDataStore';
import FormState from '../../state/uFormState';
import { dataStore } from '../InputView';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';

// interface Iprops {
//   modelKey: string;
//   readOnly: boolean;
// }

interface Istate {
  isSubmitting: boolean;
}

export let formState = new FormState();

class ApprovalsView extends React.Component<{}, Istate> {
  constructor(props: any) {
    super(props);
    formState = new FormState();
    this.state = {
      isSubmitting: false
    };
  }

  public render() {
    return (
      <Provider inject={[formState]}>
        <Subscribe to={[DataStore]}>
          {(store: DataStore) => {
            return (
              <form>
                <div style={{ flexGrow: 1, margin: '12px' }}>
                  <Paper>
                    <Grid container spacing={16}>
                      <Grid item xs={12} style={{ margin: 12 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Section</TableCell>
                              <TableCell>Reviewer</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Pore Pressure</TableCell>
                              <TableCell>Assia Almonde</TableCell>
                              <TableCell>Reviewed</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Lithology</TableCell>
                              <TableCell>Katrina Cheatham</TableCell>
                              <TableCell>Reviewed</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Well Design</TableCell>
                              <TableCell>Edward Davis</TableCell>
                              <TableCell>Reviewed</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Targets</TableCell>
                              <TableCell>Stacey Blagg</TableCell>
                              <TableCell>Reviewed</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={12} style={{ margin: 24 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          >
                          Approve and Finalize Phase
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </form>
            );
          }}
        </Subscribe>
      </Provider>
    );
  }
}

export default ApprovalsView;
