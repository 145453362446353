import React, { Component } from 'react';
import './App.css';
import NavMenu from './components/NavMenu';
import InputView from './components/InputView';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, Fab } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import DataStore from './state/uDataStore';
import { Provider } from 'unstated';
import QuestionModal from './components/QuestionModal';

const theme = createMuiTheme();

const fabStyle = {
  position: 'fixed',
  bottom: theme.spacing.unit * 2,
  right: theme.spacing.unit * 2
};

class App extends Component {
  public render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <NavMenu />
          <InputView theme={theme} />
          <QuestionModal theme={theme}/>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
