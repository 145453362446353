import React from 'react';
import { HotTable } from '@handsontable/react';
import { IappInputGroup, IppData } from '../../types/model';
import { Button, Grid, CssBaseline } from '@material-ui/core';
import { Scatter } from 'react-chartjs-2';
import Handsontable from 'handsontable';
import { isNumber } from 'util';
import _ from 'lodash';

const calcGradient = (depth: number, pressure: number) => {
  return pressure / depth / 0.0519;
};

const startData = [
  [100, 43.3],
  [1000, 433],
  [1100, 500],
  [1200, 550],
  [1300, 600],
  [1400, 700],
  [1500, 800]
];

interface Iprops {
  onUpdate: (data: number[][]) => void;
  ppData: IppData;
  readOnly: boolean;
}

export class PorePressureGrid extends React.Component<Iprops> {
  public columnHeaders: string[];
  public columns: any[];
  private hot: React.RefObject<HotTable>;
  private chart: React.RefObject<Scatter>;
  private selR = 0;
  constructor(props: Iprops) {
    super(props);
    this.hot = React.createRef();
    this.chart = React.createRef();
    this.columnHeaders = ['TVDRKB', 'pressure', 'gradient'];
    this.columns = [
      { validator: 'numeric' },
      { validator: 'numeric' },
      {
        validator: 'numeric',
        readOnly: true,
        // renderer: (hotInstance: Handsontable, td: any, row: number, column: number,
        // prop: any, value: any, cellProperties: any) => {
        renderer: (
          hotInstance: Handsontable,
          td: HTMLTableDataCellElement,
          row: number
        ) => {
          const data = this.props.ppData.data;
          const val = calcGradient(data[row][0], data[row][1]);
          if (!isNaN(val) || val > 0) {
            data[row][2] = val;
            td.innerHTML = data[row][2].toFixed(2);
            td.classList.add('htDimmed');
          } else {
            delete data[row][2];
            td.innerHTML = '';
          }
          return td;
        }
      }
    ];
  }

  public render() {
    return (
      <div>
        <div style={{ marginLeft: 12 }}>
          <HotTable
            manualColumnResize={true}
            startRows={1}
            data={this.props.ppData.data}
            wordWrap={true}
            columns={this.columns}
            colHeaders={this.columnHeaders}
            rowHeaders={true}
            contextMenu={[
              'row_above',
              'row_below',
              'remove_row',
              'undo',
              'redo',
              'cut',
              'copy'
            ]}
            stretchH="all"
            afterSelection={this.hotChange}
            afterChange={this.hotDataChange}
            ref={this.hot}
            readOnly={this.props.readOnly}
          />
        </div>
        {!this.props.readOnly ? (
          <Grid container justify="flex-end">
            <Button onClick={this.addRow}>Add Row</Button>
            <Button onClick={this.deleteRow}>Delete Row</Button>
          </Grid>
        ) : null}
      </div>
    );
  }
  public componentDidMount() {
    const change = startData.map((d, i) => [i, 0]);
    this.hotDataChange(change);
  }

  // public componentWillReceiveProps(nextProps: Iprops) {
  //   console.log()
  // }

  private addRow = () => {
    const hot = this.hot!.current!.hotInstance;
    hot.alter('insert_row');
    hot.selectCell(hot.countRows() - 1, 0);
    setTimeout(hot.render.bind(hot), 1);
  }
  private deleteRow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const hot = this.hot!.current!.hotInstance;
    hot.alter('remove_row', this.selR);
    hot.selectCell(this.selR, 0);
    hot.render();
  }
  private hotChange = (r: number, c: number) => {
    this.selR = r;
  }

  private hotDataChange = (change: any[]) => {
    // if (!change || change[0][1] === 2) return;
    // if (!this.hot || !this.hot.current) return;
    // const hot = this.hot.current.hotInstance;
    // let lastr = -1;
    // const curData = hot.getData();
    // change.forEach((chg) => {
    //   // only do calcs once per row
    //   const r = chg[0];
    //   if (r === lastr) return;
    //   lastr = r;
    //   const c = chg[1];
    //   const depth = Number(hot.getDataAtCell(r, 0));
    //   const pressure = Number(hot.getDataAtCell(r, 1));
    //   if (c === 0 || c === 1) {
    //     if (
    //       typeof depth === 'number' &&
    //       typeof pressure === 'number' &&
    //       depth > 0 &&
    //       pressure > 0
    //     ) {
    //       const grad = calcGradient(depth, pressure);
    //       hot.setDataAtCell(r, 2, grad.toFixed(2));
    //       curData[r][2] = grad;
    //     }
    //   }
    // });
    this.props.onUpdate(this.props.ppData.data);
  }
}
